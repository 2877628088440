import Image from 'next/future/image';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import searchIcon from '@/public/icons/menu/search-mobile.svg';

export default function SearchInput({
  setIsOpen,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const isCollectionPage = router.asPath.includes('sort');
  const [isVisible, setIsVisible] = useState(true);
  const level = isCollectionPage ? 115 : 0;

  useEffect(() => {
    let lastScrollTop = window.scrollY || document.documentElement.scrollTop;

    const handleScroll = () => {
      const currentScroll =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScroll > level && currentScroll > lastScrollTop && isVisible)
        setIsVisible(false); // Scrolling down, hide the component
      else if (currentScroll < lastScrollTop && !isVisible) setIsVisible(true); // Scrolling up, show the component

      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);
  if (!isVisible) return null;

  const show = `relative mt-3 rounded-md shadow-sm ${
    isVisible ? 'block' : 'hidden'
  }`;

  return (
    <div className={show} onClick={() => setIsOpen(true)}>
      <input
        type="text"
        name="account-number"
        id="account-number"
        className="block w-full rounded-md border border-gray-200 py-3 pl-3 pr-10 text-sm text-gray-900 ring-1 ring-inset ring-gray-200"
        placeholder="What are you looking for?..."
      />
      <div className=" pointer-events-none absolute inset-y-0 right-0 flex w-[35px] items-center pr-3">
        <Image
          src={searchIcon}
          alt="search icon"
          width="27"
          height="27"
          sizes="100vw"
          className="w-full"
        />
      </div>
    </div>
  );
}
