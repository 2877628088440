import Image from 'next/future/image';
import Link from 'next/link';
import DollarIcon from '@/public/icons/menu/dollar.svg';
import settings from '@/settings';
import { handleMixpanelMenuClick } from '../utils';

const BuybackCta = ({
  closeMenuPanel,
}: {
  closeMenuPanel: (val?: boolean) => void;
}) => {
  if (settings.buyback_exist) {
    return (
      <Link
        href={
          settings.store === 'quista'
            ? '/buyback/sell-electronic'
            : '/sell-phone'
        }
      >
        <button
          className="flex w-full items-center px-4 py-3 hover:bg-gray-300 focus:outline-none sm:px-5"
          onClick={() => {
            handleMixpanelMenuClick({
              menuLevel: 0,
              title: 'Sell Your Device',
              handle:
                settings.store === 'quista'
                  ? '/buyback/sell-electronic'
                  : '/sell-phone',
            });
            closeMenuPanel();
          }}
        >
          <Image
            src={DollarIcon}
            alt="Sell your device"
            height={22}
            width={22}
            className="ml-[1px] mr-3"
          />
          <span>Sell Your Device</span>
        </button>
      </Link>
    );
  }

  return null;
};

export default BuybackCta;
