import Image from 'next/future/image';
import { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { debounce } from 'lodash-es';
import { Dialog } from '@headlessui/react';
import searchIcon from '@/public/icons/menu/search-mobile.svg';
import GTM from '@/lib/google-tag-manager';
import type { SearchResponse } from '@/api/search';
import {
  mixpanel,
  triggerMixpanelEvent,
  useGetMixpanelDistinctId,
} from '@/lib/mixpanelUtils';
import { getUserAndLogBrazeEvent } from '../../brazeUtils';
import { getSavedToken } from '@/lib/auth-token';
import SearchOutputMobile from './SearchOutputMobile';
import SearchInput from './SearchInput';

interface Props {
  className: string;
  toggle?: () => void;
}

export default function SearchBarMobile(props: Props) {
  const router = useRouter();
  const qsQuery = router.query.query;
  const tokenWrapper = getSavedToken();
  const token = tokenWrapper?.token;
  const defaultQuery =
    qsQuery != null && !Array.isArray(qsQuery) ? qsQuery : '';

  const [search, setSearch] = useState(defaultQuery);
  const [results, setResults] = useState<SearchResponse | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSearch(defaultQuery);
  }, [defaultQuery]);

  const debounceGTM = useMemo(
    () =>
      debounce((query: string) => {
        GTM.genericEvent({ event: 'search_products', query });
      }, 500),
    [],
  );

  const mobileMenuToggle = () => {
    props.toggle?.();
    setSearch(defaultQuery);
  };

  const onClose = () => setIsOpen(false);

  const { mixpanelDistinctId } = useGetMixpanelDistinctId();

  const onSubmit = async () => {
    const trimmedSearch = encodeURIComponent(search.trim());

    if (trimmedSearch.length === 0) return;

    if (search !== '' && results != null) {
      mixpanel.people.increment('# of Searches');

      triggerMixpanelEvent({
        eventName: 'Search Submitted',
        props: {
          'Search Term': search,
          'Search Character Length': search.length,
          Results: results.offers.length + results.collections.length > 0,
          'From Suggestion': 'No',
        },
      });

      getUserAndLogBrazeEvent({
        eventName: 'Search_Submitted',
        token,
        mixpanelDistinctId,
        payload: {
          search_term: search,
          search_character_length: search.length,
          results: results.offers.length + results.collections.length > 0,
          from_suggestion: 'No',
          // position: 0 refers to the position of search term selected out of all search results
          // number_of_searches: numberOfSearches,
        },
      });
    }
    props.toggle?.();
    onClose();
    await router.push(`/search?query=${trimmedSearch}`);
  };

  if (!isOpen) return <SearchInput setIsOpen={setIsOpen} />;

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-[50] mt-10 overflow-hidden p-2 sm:hidden"
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-700/[.3]" />
      <SearchOutputMobile
        search={search === defaultQuery ? '' : search}
        className={props.className}
        toggle={() => mobileMenuToggle()}
        results={results}
        setResults={setResults}
        onClose={onClose}
      >
        <form
          className="flex h-10 w-auto items-center rounded-md border border-gray-200 bg-white px-4"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <input
            type="text"
            value={search}
            onChange={(e) => {
              const searchQuery = e.target.value;

              setSearch(searchQuery);

              debounceGTM(searchQuery);
            }}
            placeholder="What are you looking for?..."
            className="w-full px-1 focus:outline-none"
            aria-label="Search Input"
            id="e2e-searchbar-search-input"
            autoComplete="off"
          />
          <button
            type="submit"
            className="transform-wiggle flex h-10 items-center justify-center transition-all duration-500 hover:rotate-90 focus:rotate-90 active:rotate-90"
            aria-label="Submit for search"
            id="e2e-searchbar-search-button"
          >
            <div className="w-[20px] xs:w-[23px]">
              <Image
                src={searchIcon}
                alt="search icon"
                width="27"
                height="27"
                sizes="100vw"
                className="w-full"
              />
            </div>
          </button>
        </form>
      </SearchOutputMobile>
    </Dialog>
  );
}
