import { ServerResponse } from 'http';
import { EXPIRE_COOKIE, TOKEN_COOKIE } from './auth-token';
import shopify from './shopify';

export function redirectLoginWithCallback(redirectURL?: string) {
  return {
    redirect: {
      destination: `/account/login${
        redirectURL !== '' ? `?redirectUrl=${redirectURL}` : null
      }`,
      permanent: false,
    },
  };
}

export function removeAuthCookies(res: ServerResponse) {
  res.setHeader('Set-Cookie', [
    `${TOKEN_COOKIE}=deleted; Max-Age=0`,
    `${EXPIRE_COOKIE}=deleted; Max-Age=0`,
  ]);
}

export const authTokenIsValid = async (token?: string) => {
  if (token == null) return false;

  const response = await shopify.fetch({
    query: `query($token: String!) {
      customer(customerAccessToken: $token) {
        id
      }
    }`,
    variables: { token },
  });

  if (!response.customer?.id) return false;

  return true;
};

export const fetchCheckAuthData = async (token?: string) => {
  const validAuth = await authTokenIsValid(token);

  return validAuth;
};
