import { PropsWithChildren, useState, useContext } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/future/image';
import * as Icons from './Icons';
import SearchBar from './SearchInput';
import { AuthContext } from '@/lib/auth-token';
import { initializeCart } from '@/lib/use-cart';
import useIsomorphicLayoutEffect from '@/lib/use-iso-layout-effects';
import settings from '@/settings';
import i18n from '@/settings/i18n';
import droppedPin from '@/public/images/droppedPin.svg';
import { AppCtx } from '../../../pages/_app';
import DeliveryByPopup from './DeliveryByPopup';
import { ZipcodeStateData } from '@/lib/use-customer-zipcode';
import { fetchCheckAuthData } from '@/lib/use-login';
import useAuth from '@/lib/use-auth';

const t = i18n.init();

const AccountMenu = dynamic(() => import('./AccountMenu'), {
  loading: (props: any) => <>{props.children}</>,
});
const AccountDrawer = dynamic(() => import('./AccountDrawer'), {
  loading: (props: any) => <>{props.children}</>,
});
const LoginModal = dynamic(() => import('./LoginModal'), {
  loading: (props: any) => <>{props.children}</>,
});

const Icon = (
  props: PropsWithChildren<{
    label: string;
    href?: string;
    className?: string;
    onClick?: () => void;
  }>,
) => {
  const className = cn(
    props.className,
    'decoration-none relative flex w-8 flex-col items-center text-sm sm:w-10  lg:w-12',
  );
  const content = (
    <>
      <span className="h-[26px] w-[26px] xxs:h-[28px] xxs:w-[28px]">
        {props.children}
      </span>
      <span className="mt-1 hidden text-sm lg:block">{t(props.label)}</span>
    </>
  );

  if (props.href) {
    return (
      <Link href={props.href} prefetch={false}>
        <a
          className={className}
          id={`e2e-header-${props.label.replace(/\s+/g, '-').toLowerCase()}`}
          aria-label={`${props.label} Link`}
        >
          {content}
        </a>
      </Link>
    );
  }

  return (
    <button
      onClick={props.onClick}
      className={className}
      id={`e2e-header-${props.label.replace(/\s+/g, '-').toLowerCase()}`}
      aria-label={`${props.label} button`}
    >
      {content}
    </button>
  );
};

const AccountIcon = ({ isLoggedIn }: { isLoggedIn: boolean | null }) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);

  const [showAccountDrawer, setShowAccountDrawer] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const label = isLoggedIn ? 'Account' : 'Sign in';

  return (
    <div ref={setReferenceElement}>
      {/* Large Screens */}
      <Icon
        label={label}
        onClick={() => {
          if (isLoggedIn != null && !isLoggedIn) setShowLoginModal(true);
          else setShowAccountDrawer(true);
        }}
        className="hidden lg:flex"
      >
        <Icons.Account />
      </Icon>

      {/* Small Screens */}
      <Icon
        label={label}
        onClick={() => {
          if (isLoggedIn != null && !isLoggedIn) setShowLoginModal(true);
          else setShowAccountModal(true);
        }}
        className="lg:hidden"
      >
        <Icons.Account />
      </Icon>
      {/* Account Menu */}
      <AccountMenu
        open={showAccountDrawer}
        setOpen={setShowAccountDrawer}
        referenceElement={referenceElement}
      />
      {/* Account Drawer */}
      <AccountDrawer open={showAccountModal} setOpen={setShowAccountModal} />
      {/* Login Modal */}
      <LoginModal
        open={showLoginModal}
        onClose={(e) => {
          e?.stopPropagation?.(); // we stop the propagation otherwise the parent will be clicked and the modal will open again
          setShowLoginModal(false);
        }}
      />
    </div>
  );
};

export default function AccessBar(props: {
  toggle?: () => void;
  className: string;
  cartIconBubbleClass: string;
}) {
  const router = useRouter();
  const { cartCount, setCartCount, setCart, setIsLoadingCart } =
    useContext(AppCtx);
  const { setZipcodeStateData, zipcodeStateData } = useContext(AppCtx);
  const [isDeliverByOpen, setIsDeliverByOpen] = useState(false);

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined' || cartCount == null || cartCount !== 0)
      return;

    initializeCart(setIsLoadingCart).then(({ cart, count }) => {
      setCart(cart);
      setCartCount(count);
    });
  }, [router.route]);

  /** Here we *don't use useAuth* to avoid having to download the Shopify API in all pages. */
  // set it initial state to null to represent state of awaiting get token from client side
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  const contextAccessToken = useContext(AuthContext);
  const auth = useAuth();

  useIsomorphicLayoutEffect(() => {
    // page reload will trigger this check
    fetchCheckAuthData(contextAccessToken?.token).then((validAuth) => {
      if (!validAuth) {
        auth.clearAuthToken();
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    });
  }, []);

  const handleStateNameChange = (data: ZipcodeStateData) => {
    setZipcodeStateData(data);
  };

  return (
    <div className={props.className}>
      <button
        className="flex w-8 outline-none xxs:w-auto lg:hidden"
        aria-label="Search bar and menu"
        onClick={() => {
          props.toggle?.();
        }}
      >
        <Icons.Menu />
      </button>
      <div className="mt-2 hidden items-center hover:cursor-pointer lg:mr-10 lg:flex">
        <Link href="/" prefetch={false} legacyBehavior>
          <a aria-label="Link to Home" id="icon-home-desktop">
            <Icons.Logo />
          </a>
        </Link>
      </div>
      <div className="flex items-center justify-center hover:cursor-pointer lg:hidden">
        <Link href="/" prefetch={false} legacyBehavior>
          <a aria-label="Link to Home" id="icon-home-mobile">
            <Icons.LogoMobile />
          </a>
        </Link>
      </div>
      <SearchBar className="mx-4 hidden max-w-24 self-start pt-1 sm:block sm:max-w-120 lg:min-w-[280px] xl:min-w-[400px]" />
      <div className="ml-auto flex items-end gap-1 justify-self-end xxs:gap-2 xs:gap-3 xl:gap-4 ">
        {['reebelo-au', 'reebelo-nz', 'reebelo-ca'].includes(
          settings.store,
        ) && (
          <>
            <DeliveryByPopup
              isOpen={isDeliverByOpen}
              onClose={() => setIsDeliverByOpen(false)}
              onStateNameChange={handleStateNameChange}
            />
            <div
              className="hidden min-w-[200px] cursor-pointer justify-end border-r border-gray-200 pr-5 lg:flex xl:pr-8"
              onClick={() => setIsDeliverByOpen(true)}
            >
              <div className="mb-[5px] flex items-end">
                <div className="relative w-[15px]">
                  <Image
                    src={droppedPin}
                    alt="Deliver to"
                    width={10}
                    height={12}
                    className="w-full"
                  />
                </div>
              </div>
              <div className="ml-2 text-sm xl:text-base">
                <div className="leading-none text-slate-gray-300">
                  Deliver to
                </div>

                <div className="text-gray-700">
                  {zipcodeStateData?.stateName || '-'}
                </div>
              </div>
            </div>
          </>
        )}
        <Icon
          label="Help"
          href={
            settings.store === 'reebelo-ca'
              ? settings.externals.help_center
              : '/help'
          }
          className="hidden pl-2 lg:flex"
        >
          <Icons.Faq />
        </Icon>
        <Icon label="About" href="/about-us" className="hidden lg:flex">
          <Icons.About />
        </Icon>
        <AccountIcon isLoggedIn={isLoggedIn} />
        <Icon label="Cart" href="/cart">
          <Icons.Cart />
          <span
            id="access-bar-count"
            className={cn(
              'absolute top-0 right-0 translate-x-2/4 -translate-y-2/4 rounded-full bg-white py-1 px-2 text-center text-xs font-bold leading-none text-gray-700 lg:top-1 lg:right-1 lg:bg-teal-500',
              { hidden: cartCount === 0 },
            )}
          >
            {cartCount}
            <span className="hidden">number of items in cart</span>
          </span>
        </Icon>
      </div>
    </div>
  );
}
