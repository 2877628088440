import collections from '@/settings/collections';
import { CollectionSummaries } from '@/api/collections/summary';
import { MenuItem } from '@/components/HeaderMenu/MenuPanel/types';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';

export function handleMixpanelMenuClick({
  title,
  menuLevel,
  handle,
  source,
  layout,
}: {
  title?: string;
  menuLevel: number;
  handle: string;
  source?: string;
  layout?: string;
}) {
  triggerMixpanelEvent({
    eventName: `Menu Item Clicked`,
    props: {
      'Menu Name': title,
      'Menu Handle': handle,
      'Menu Level': menuLevel,
      Source: source,
      'Menu Layout': layout,
    },
  });
}

export function chunkArray(arr: MenuItem[], sliceEnd: number) {
  const chunkedArray = [];

  for (let i = 0; i < arr.length; i += sliceEnd)
    chunkedArray.push(arr.slice(i, i + sliceEnd));

  return chunkedArray;
}

export function getInStockItems(
  input: MenuItem[] | MenuItem,
  referenceSet: CollectionSummaries,
) {
  if (Array.isArray(input)) {
    const filteredArray: MenuItem[] | never = [];

    input.forEach((item) => {
      if (referenceSet?.[item.handle]) {
        const newItem = {
          ...item,
          ...referenceSet[item.handle],
          ...collections[item.handle],
        };

        if (newItem.links != null && newItem.links.length > 0) {
          newItem.links = getInStockItems(
            newItem.links as MenuItem[],
            referenceSet,
          );
        }

        filteredArray.push(newItem);
      }
    });

    return filteredArray;
  }

  return [];
}
