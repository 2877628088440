/* eslint-disable react/forbid-dom-props */
import Link from 'next/link';
import {
  AccessoriesProps,
  CollectionCardProps,
} from '@/components/HeaderMenu/MenuPanel/types';
import collections from '@/settings/collections';
import { chunkArray, handleMixpanelMenuClick } from '../utils';

const CollectionCard = ({ item, closeMenuPanel }: CollectionCardProps) => {
  const formattedList = item.links ? chunkArray(item.links, 3) : [];

  return (
    <div className="flex flex-col">
      <Link href={`/collections/${item.handle}`}>
        <a
          onClick={() => {
            handleMixpanelMenuClick({
              title: item?.title,
              handle: item.handle,
              menuLevel: 2,
              layout: 'Accessories',
            });
            closeMenuPanel();
          }}
          className="flex cursor-pointer text-sm font-bold hover:underline"
        >
          <h2>{collections[item.handle].title}</h2>
        </a>
      </Link>
      <table className="mt-3 table w-full table-fixed">
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        {formattedList.map((listItem) => (
          <tr>
            {listItem.map((subItem) => (
              <td>
                <Link
                  href={`/collections/${subItem.handle}`}
                  className="w-full"
                >
                  <a
                    className="block w-full py-1 hover:underline"
                    onClick={() => {
                      handleMixpanelMenuClick({
                        title: subItem?.title,
                        handle: subItem.handle,
                        menuLevel: 3,
                        layout: 'Accessories',
                      });
                      closeMenuPanel();
                    }}
                  >
                    {collections[subItem.handle].title}
                  </a>
                </Link>
              </td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
};

const Accessories = ({ items, closeMenuPanel }: AccessoriesProps) => (
  <tr className="border-b last:border-b-0">
    <td className="p-5">
      <CollectionCard item={items[0]} closeMenuPanel={closeMenuPanel} />
    </td>
  </tr>
);

export default Accessories;
