/* eslint-disable react/forbid-dom-props */
import Image from 'next/future/image';
import Link from 'next/link';
import {
  NavCardProps,
  NavProps,
  MenuItem,
} from '@/components/HeaderMenu/MenuPanel/types';
import collections from '@/settings/collections';
import Badge from '@/components/HeaderMenu/MenuPanel/common/Badge';
import { handleMixpanelMenuClick } from '../utils';

const NavCard = ({ item, closeMenuPanel }: NavCardProps) => {
  const { handle, imageUrl } = item;
  const { title } = collections[handle];

  return (
    <div className="px-8 py-5 sm:px-10">
      {item?.badge && (
        <Badge label={item.badge} className="absolute left-3 top-3" />
      )}
      <div className="flex">
        <Link href={`/collections/${handle}`}>
          <a className="mr-4 flex h-[100px] w-[130px] cursor-pointer">
            <span className="h-[100px] w-[130px]">
              {imageUrl ? (
                <Image
                  onClick={() => {
                    handleMixpanelMenuClick({
                      title,
                      handle,
                      source: 'Image',
                      menuLevel: 2,
                      layout: 'Nav',
                    });
                    closeMenuPanel();
                  }}
                  src={(item?.links?.[0] as MenuItem)?.imageUrl ?? imageUrl}
                  height={100}
                  width={130}
                  alt={title}
                  className="h-[100px] w-[130px] cursor-pointer object-contain"
                  priority
                />
              ) : (
                <div className="h-[100px] w-[130px] animate-pulse rounded-md bg-gray-500"></div>
              )}
            </span>
          </a>
        </Link>
        <div className="w-full">
          <Link href={`/collections/${handle}`}>
            <a
              onClick={() => {
                handleMixpanelMenuClick({
                  title,
                  handle,
                  source: 'Title',
                  menuLevel: 2,
                  layout: 'Nav',
                });
                closeMenuPanel();
              }}
              className="mb-3 block text-sm font-bold hover:underline"
            >
              <h2>{title}</h2>
            </a>
          </Link>
          <div className="flex flex-col items-start justify-start gap-1">
            {item?.links?.length
              ? item.links.slice(0, 5).map((subItem: MenuItem) => {
                  const subTitle = collections[subItem.handle].title;
                  const subHandle = subItem.handle;

                  return (
                    <Link href={`/collections/${subHandle}`}>
                      <a
                        onClick={() => {
                          handleMixpanelMenuClick({
                            title: subTitle,
                            handle: subHandle,
                            menuLevel: 3,
                            layout: 'Nav',
                          });
                          closeMenuPanel();
                        }}
                        className="block w-full hover:underline"
                      >
                        <h3>{subTitle}</h3>
                      </a>
                    </Link>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Nav = ({ items, closeMenuPanel, cardHeight }: NavProps) => (
  <tr className="border-b last:border-b-0" style={{ height: cardHeight }}>
    <td className="relative">
      <NavCard item={items[0]} closeMenuPanel={closeMenuPanel} />
    </td>
    {items[1] && (
      <td className="relative">
        <NavCard item={items[1]} closeMenuPanel={closeMenuPanel} />
      </td>
    )}
  </tr>
);

export default Nav;
