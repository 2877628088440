import Image from 'next/future/image';
import { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { debounce } from 'lodash-es';
import searchIcon from '@/public/icons/menu/search.svg';
import SearchOutput from './SearchOutput';
import GTM from '@/lib/google-tag-manager';
import i18n from '@/settings/i18n';
import type { SearchResponse } from '@/api/search';
import {
  mixpanel,
  triggerMixpanelEvent,
  useGetMixpanelDistinctId,
} from '@/lib/mixpanelUtils';
import { getUserAndLogBrazeEvent } from '../brazeUtils';
import { getSavedToken } from '@/lib/auth-token';

const t = i18n.init();
interface Props {
  className: string;
  toggle?: () => void;
}

export default function SearchBar(props: Props) {
  const router = useRouter();
  const qsQuery = router.query.query;
  const tokenWrapper = getSavedToken();
  const token = tokenWrapper?.token;
  const defaultQuery =
    qsQuery != null && !Array.isArray(qsQuery) ? qsQuery : '';

  const [search, setSearch] = useState(defaultQuery);
  const [results, setResults] = useState<SearchResponse | null>(null);

  const [showRecommendations, setShowRecommendations] = useState(false);

  useEffect(() => {
    setSearch(defaultQuery);
  }, [defaultQuery]);

  const debounceGTM = useMemo(
    () =>
      debounce((query: string) => {
        GTM.genericEvent({ event: 'search_products', query });
      }, 500),
    [],
  );

  const mobileMenuToggle = () => {
    props.toggle?.();
    setSearch(defaultQuery);
  };

  const { mixpanelDistinctId } = useGetMixpanelDistinctId();

  const onSubmit = async () => {
    const trimmedSearch = encodeURIComponent(search.trim());

    if (trimmedSearch.length === 0) return;

    if (search !== '' && results != null) {
      mixpanel.people.increment('# of Searches');

      triggerMixpanelEvent({
        eventName: 'Search Submitted',
        props: {
          'Search Term': search,
          'Search Character Length': search.length,
          Results: results.offers.length + results.collections.length > 0,
          'From Suggestion': 'No',
        },
      });

      getUserAndLogBrazeEvent({
        eventName: 'Search_Submitted',
        token,
        mixpanelDistinctId,
        payload: {
          search_term: search,
          search_character_length: search.length,
          results: results.offers.length + results.collections.length > 0,
          from_suggestion: 'No',
          // position: 0 refers to the position of search term selected out of all search results
          // number_of_searches: numberOfSearches,
        },
      });
    }
    props.toggle?.();
    await router.push(`/search?query=${trimmedSearch}`);
  };

  return (
    <SearchOutput
      onOutsideClick={() => {
        setShowRecommendations(false);
      }}
      search={search === defaultQuery ? '' : search}
      className={props.className}
      toggle={() => mobileMenuToggle()}
      results={results}
      setResults={setResults}
      show={showRecommendations}
    >
      <form
        className="flex h-10 w-full items-center bg-gray-200 px-4 sm:h-11"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <input
          type="text"
          value={search}
          onChange={(e) => {
            const searchQuery = e.target.value;

            setSearch(searchQuery);

            debounceGTM(searchQuery);
          }}
          placeholder={t`Search by model, color, brand...`}
          className="w-full border-0 bg-gray-200 focus:outline-none"
          aria-label="Search Input"
          id="e2e-searchbar-search-input"
          autoComplete="off"
          onFocus={() => setShowRecommendations(true)}
        />
        <button
          type="submit"
          className="transform-wiggle flex h-10 items-center justify-center transition-all duration-500 hover:rotate-90 focus:rotate-90 active:rotate-90"
          aria-label="Submit for search"
          id="e2e-searchbar-search-button"
        >
          <div className="w-[24px] xs:w-[27px]">
            <Image
              src={searchIcon}
              alt="search icon"
              width="27"
              height="27"
              sizes="100vw"
              className="w-full"
            />
          </div>
        </button>
      </form>
    </SearchOutput>
  );
}
