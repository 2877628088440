import cn from 'classnames';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import settings from '@/settings';
import templateMenu from '@/settings/menus';
import { MenuItem } from '@/settings/types';
import { useCheapestSummary } from '@/lib/use-cheapset-summary';
import type { CollectionSummaries } from '@/api/collections/summary';
import i18n from '@/settings/i18n';
import { extractCollectionTitle } from '../commons/Header/MenuBar/helpers';
import useSubscribeFF from '@/lib/useSubscribeFF';
import BurgerIcon from '@/public/icons/menu/burger.svg';
import MenuPanel from './MenuPanel';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';

const t = i18n.init();

const DynamicMenuItem = dynamic(() => import('./DynamicMenuItem'), {
  loading: () => <div>Loading...</div>,
});

function getSubmenuItemClassesByLevel(level: number) {
  const commonSubMenuClasses = 'submenu absolute hidden w-64 flex-col';

  switch (level) {
    case 0:
      return 'flex w-full font-sans lg:text-sm text-[.8rem] main-menu';

    case 1:
      return `${commonSubMenuClasses} -left-4 top-full bg-white`;

    case 2:
      return `${commonSubMenuClasses} left-full top-0 min-h-full max-h-[calc(100vh_-_130px)] overflow-y-auto bg-gray-200`;

    default:
      return `${commonSubMenuClasses} left-full bg-white top-0 h-full overflow-y-auto`;
  }
}

type MenuClickHandlerProps = {
  handle: string;
  title: string;
  menuLevel: number;
  index: number;
  psku?: string;
};

function handleMenuClick(menuClickProps: MenuClickHandlerProps) {
  const { handle, psku, title, menuLevel, index } = menuClickProps;

  triggerMixpanelEvent({
    eventName: `Menu Item Clicked`,
    props: {
      'Section Type':
        menuLevel > 0 ? 'Catalog Vertical Tray' : 'Featured Horizonal Bar',
      'Menu Name': handle,
      'Item Last Level Position': menuLevel > 0 ? index : undefined,
      'Item Name': menuLevel > 1 ? title : undefined,
      'Item PSKU': psku,
    },
  });
}

export function getInStockItems(
  menu: MenuItem[],
  availableItems?: CollectionSummaries,
) {
  if (!menu) return null;
  if (!availableItems) return menu;

  return menu.reduce((inStock, { handle, ...rest }: MenuItem) => {
    if (!availableItems[handle]) return inStock;

    return [
      ...inStock,
      {
        ...availableItems[handle],
        ...rest,
        handle,
      },
    ];
  }, [] as MenuItem[]);
}

type MenuProps = {
  menuData: MenuItem[] | null;
  allMenuItems?: CollectionSummaries;
  menuLevel: number;
  setShowMenuPanel?: (val: boolean) => void;
};

export const Menu = ({
  menuData,
  allMenuItems,
  menuLevel,
  setShowMenuPanel,
}: MenuProps) => {
  const { properties: dealsMenuTitleFFProps } =
    useSubscribeFF('deals-page-title');

  if (!menuData || menuData.length === 0) return null;

  const dealsMenuTitle =
    (dealsMenuTitleFFProps?.menu?.value as string) ?? 'Deals';

  return (
    <ul className={getSubmenuItemClassesByLevel(menuLevel)}>
      <li className={cn('menu-item relative hidden lg:block')}>
        {menuLevel === 0 && (
          <button
            onClick={() =>
              typeof setShowMenuPanel === 'function' && setShowMenuPanel(true)
            }
            className="flex w-full items-center justify-center px-3 py-4 pl-0 pr-10 outline-none hover:[text-shadow:0.5px_0px_0px_#000]"
          >
            <div className="-mt-0.5 mr-2 flex h-[12px] w-[12px] items-center justify-center">
              <span className="h-[12px] w-[12px]">
                <Image
                  src={BurgerIcon}
                  height={12}
                  width={12}
                  alt="All Items"
                  className="h-full w-full"
                />
              </span>
            </div>
            <span className="whitespace-nowrap">All Items</span>
          </button>
        )}
      </li>
      {menuLevel === 0 && (
        <li className={cn('menu-item relative')}>
          <Link href="/collections/hot-deals">
            <a
              className="flex w-full px-3 py-4 pl-0 pr-6 hover:[text-shadow:0.5px_0px_0px_#000]"
              onClick={() => {
                handleMenuClick({
                  handle: 'hot-deals',
                  title: 'Hot Deals',
                  menuLevel,
                  index: 0,
                });
              }}
            >
              <span className="whitespace-nowrap">{dealsMenuTitle}</span>
            </a>
          </Link>
        </li>
      )}
      {menuData.map(({ handle, links, menuSectionHeader }, index) => {
        const inStockChildLinks = getInStockItems(
          links as MenuItem[],
          allMenuItems,
        );

        if (handle !== null && menuSectionHeader) {
          return (
            <li
              key={handle}
              className={cn(
                'menu-item',
                { relative: menuLevel === 0 },
                { 'hover:bg-gray-200': menuLevel !== 0 },
              )}
            >
              <Link
                href={
                  handle.includes('reebelos-gift-card')
                    ? `/${handle}`
                    : `/collections/${handle}`
                }
                prefetch={false}
              >
                <a
                  className={cn(
                    'flex w-full px-3 font-bold hover:[text-shadow:0.5px_0px_0px_#000]',
                    { 'py-4': menuLevel === 0 },
                    { 'py-2 hover:bg-gray-200': menuLevel !== 0 },
                    { 'pl-0': menuLevel === 0 && index === 0 },
                  )}
                  onClick={() =>
                    handleMenuClick({
                      handle,
                      title: 'reebelos-gift-card',
                      menuLevel,
                      index,
                    })
                  }
                >
                  <span>{extractCollectionTitle(handle)}</span>
                </a>
              </Link>
            </li>
          );
        }

        return (
          <DynamicMenuItem
            key={handle}
            handle={handle}
            menuLevel={menuLevel}
            links={links}
            inStockChildLinks={inStockChildLinks}
            allMenuItems={allMenuItems}
            index={index}
            handleMenuClick={handleMenuClick}
          />
        );
      })}
      {settings.buyback_exist && menuLevel === 0 && (
        <li className="ml-auto flex items-center py-0 pr-3 lg:pr-0">
          <a
            className="h-fit whitespace-nowrap rounded-full border border-gray-700 px-2 py-1 text-[0.7rem] hover:[text-shadow:0.5px_0px_0px_#000]  sm:text-[0.48rem] lg:text-sm"
            href={
              settings.store === 'quista'
                ? '/buyback/sell-electronic'
                : '/sell-phone'
            }
            onClick={() =>
              handleMenuClick({
                handle:
                  settings.store === 'quista'
                    ? '/buyback/sell-electronic'
                    : '/sell-phone',
                title: 'Sell Your Device',
                menuLevel,
                index: 0,
              })
            }
          >
            {t`Sell Your Device`}
          </a>
        </li>
      )}
    </ul>
  );
};

export default function HeaderMenu({
  showMenuPanel,
  setShowMenuPanel,
}: {
  showMenuPanel: boolean;
  setShowMenuPanel: (val: boolean) => void;
}) {
  const allMenuItems = useCheapestSummary();
  const filteredMenuItems = getInStockItems(templateMenu, allMenuItems);

  return (
    <>
      <Menu
        menuData={filteredMenuItems}
        allMenuItems={allMenuItems}
        menuLevel={0}
        setShowMenuPanel={setShowMenuPanel}
      />
      <MenuPanel showMenu={showMenuPanel} setShowMenu={setShowMenuPanel} />
    </>
  );
}
