import { useContext, useState } from 'react';
import Link from 'next/link';
import Image from 'next/future/image';
import useAuth from '@/lib/use-auth';
import { AuthContext } from '@/lib/auth-token';
import useIsomorphicLayoutEffect from '@/lib/use-iso-layout-effects';
import { fetchCheckAuthData } from '@/lib/use-login';
import AccountIcon from '@/public/icons/menu/account.svg';
import { handleMixpanelMenuClick } from '../utils';

const AccountCta = ({
  closeMenuPanel,
}: {
  closeMenuPanel: (val?: boolean) => void;
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  const contextAccessToken = useContext(AuthContext);
  const auth = useAuth();

  useIsomorphicLayoutEffect(() => {
    // page reload will trigger this check
    fetchCheckAuthData(contextAccessToken?.token).then((validAuth) => {
      if (!validAuth) {
        auth.clearAuthToken();
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    });
  }, []);

  const label = isLoggedIn ? 'Account' : 'Sign in';

  return (
    <Link href={isLoggedIn ? '/account/orders' : '/account/login'}>
      <button
        className="flex w-full items-center px-4 py-3 hover:bg-gray-300 focus:outline-none sm:px-5"
        onClick={() => {
          handleMixpanelMenuClick({
            menuLevel: 0,
            title: label,
            handle: isLoggedIn ? '/account/orders' : '/account/login',
          });
          closeMenuPanel();
        }}
      >
        <Image
          src={AccountIcon}
          alt="Sign in"
          height={25}
          width={25}
          className="mr-3"
        />
        <span>{isLoggedIn != null && label}</span>
      </button>
    </Link>
  );
};

export default AccountCta;
