import { useCallback, useContext, useState } from 'react';
import router from 'next/router';
import { mixpanel } from '@/lib/mixpanelUtils';
import useShopify from './use-shopify';
import { AuthContext, setAccessToken } from './auth-token';
import shopifyApi from '@/lib/shopify';

export const mixpanelIdentifyUser = async (token: string) => {
  const auth = await shopifyApi.fetch({
    query: `query($token: String!){
                customer(customerAccessToken:$token) {
                  firstName
                  lastName
                  id
                  phone
                  createdAt
                  email
                }
              }`,
    variables: { token },
  });

  if (auth.customer !== null) {
    const customerId = auth.customer.id;
    const idMatch = customerId.match(/(\d+)/);

    mixpanel.identify(idMatch?.[0] ?? customerId);
    mixpanel.people.set_once({
      '# of Searches': 0,
      $shopify_id: customerId,
      $createdAt: auth.customer.createdAt,
    });
    mixpanel.people.set({
      $name: `${auth.customer.firstName} ${auth.customer.lastName}`,
      $first_name: auth.customer.firstName,
      $last_name: auth.customer.lastName,
      $email: auth.customer.email,
      $phone: auth.customer.phone,
    });
  }
};

const useCb = <T, R extends Array<any>>(fn: (...args: R) => T) =>
  useCallback(fn, []); // avoids unecessary renderings in components

/**
 * @param defaultAccessToken For SSR, pass the access token from the request
 */
export default function useAuth(defaultAccessToken?: string) {
  const [loading, shopify] = useShopify();
  const contextAccessToken = useContext(AuthContext);
  const [error, setError] = useState<any>('');
  const accessToken =
    typeof window === 'undefined' && defaultAccessToken
      ? { token: defaultAccessToken, expireAt: new Date().toISOString() } // Server token with dummy `expireAt`
      : contextAccessToken; // Client token

  const loginWithoutRedirection = useCb(
    async (
      input: { email: string; password: string },
      // eslint-disable-next-line consistent-return
    ): Promise<{ error: any } | { accessToken: string }> => {
      const response = await shopify({
        query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                  customerAccessTokenCreate(input: $input) {
                    customerAccessToken { accessToken expiresAt }
                    customerUserErrors { message }
                  }
                }`,
        variables: { input },
      });
      const res = response.customerAccessTokenCreate.customerAccessToken;
      const err: any =
        response?.customerAccessTokenCreate?.customerUserErrors[0]?.message;

      setError(err);

      if (res == null) {
        setAccessToken(null);

        return { error: err };
      }

      await setAccessToken({ token: res.accessToken, expireAt: res.expiresAt });

      return { accessToken: res.accessToken };
    },
  );

  const login = useCb(
    async (
      input: { email: string; password: string },
      redirectToAfterSuccess?: string,
      // eslint-disable-next-line consistent-return
    ) => {
      const loginResult = await loginWithoutRedirection(input);

      if ('error' in loginResult) return loginResult.error;
      await mixpanelIdentifyUser(loginResult.accessToken);

      if (redirectToAfterSuccess) window.location.href = redirectToAfterSuccess;
      else router.reload();
    },
  );

  const loginWithToken = async (
    token: string,
    expireAt: string,
    redirectPath: string,
  ) => {
    await setAccessToken({ token, expireAt });
    window.location.href = redirectPath;
  };

  const clearAuthToken = async () => {
    await setAccessToken(null);
  };

  const logout = useCb(async () => {
    const customerAccessToken = accessToken?.token;

    if (customerAccessToken != null) {
      await shopify({
        query: `mutation customerAccessTokenDelete($customerAccessToken: String!) {
                customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                  deletedAccessToken
                  deletedCustomerAccessTokenId
                  userErrors { field message }
                }
              }`,
        variables: { customerAccessToken },
      });
    }

    mixpanel.reset();

    await setAccessToken(null);
    router.reload();
  });

  const onSetError = (err: string) => {
    setError(err);
  };

  return {
    login,
    loginWithToken,
    loginWithoutRedirection,
    clearAuthToken,
    logout,
    loading,
    isLoggedIn: accessToken != null,
    customerAccessToken: accessToken?.token,
    error,
    setError,
    onSetError,
  };
}
