import { pickBy } from 'lodash-es';
import { MenuItem } from '@/settings/types';
import type { CollectionSummaries } from '@/api/collections/summary';
import collections from '@/settings/collections';
import i18n from '@/settings/i18n';

const t = i18n.init();

export const filterInactiveMenus = (
  menu: MenuItem[],
  handles: CollectionSummaries,
) => {
  const activeHandles = pickBy(handles, (item) => item != null);

  return menu
    .filter((item) => activeHandles[item.handle] != null)
    .map((item) => ({
      handle: item.handle,
      menuSectionHeader: item.menuSectionHeader || false,
      links: item.links?.filter(
        (menuItem) => activeHandles[menuItem.handle] != null,
      ),
    }));
};

export const extractCollectionTitle = (handle: string | undefined | null) => {
  if (handle) {
    const handleToTitleMap = {
      'more-devices': t`All Other Products`,
      'other-smartphones': t`See All Smartphones`,
    };

    return handleToTitleMap[handle as keyof typeof handleToTitleMap]
      ? handleToTitleMap[handle as keyof typeof handleToTitleMap]
      : `All ${collections[handle as keyof typeof collections].title}`;
  }

  return '';
};
